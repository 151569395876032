import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CategoryCard from "../components/Categories/CategoryCard"; 

import CategoryCardFactory from "../features/Categories/CategoryCardFactory";
import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
import HomeRepository from "../features/Home/HomeRepository";

const breadcrumbsFactory = new BreadcrumbsFactory();
const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(42rem, 1fr));
  grid-template-rows: auto;
  grid-gap: 2.5rem;
  grid-auto-rows: 1fr;

  padding: 1rem 0 8rem 0;

  .heading {
    grid-column: 1 / -1;
  }
`;

const Categories = ({ data }) => {
  const categoryCards = CategoryCardFactory.fromArray(data.categories.nodes);
  const updatedCategoryCards = HomeRepository.addImagesToCategories(categoryCards, data.categoryImages.nodes)
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'categories',
      title: 'Категории',
    },
  ]);

  return (
    <Layout crumbs={ crumbs }>
      <SEO title="Запчасти Toyota" />
      <CategoriesContainer className="container">
        <div className="heading">
          <h1>Категории</h1>
        </div>
        {categoryCards.map(category => (
          <CategoryCard
            key={category.slug}
            title={category.title}
            image={category.image}
            preview={category.preview}
            url={`/${category.slug}`}
          />
        ))}
      </CategoriesContainer>
    </Layout>
  )
}

export const query = graphql`
  query ALL_CATEGORIES_QUERY {
    categories: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/categories/"}}, 
        sort: {fields: frontmatter___sort}
      ) {
      nodes {
        frontmatter {
          title
          shortName
          slug
          image
          description
        }
      }
    }
    categoryImages:allFile(filter: {dir: {regex: "/cdn/categories/"}}) {
      nodes {
        name
        childImageSharp {
          fixed(width: 65, height: 65) {
            aspectRatio
            height
            src
            srcSet
            width
          }
        }
      }
    }
  }
`;

export default Categories;
