import React from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import { HorizontalCard } from "../UI/Cards/HorizontalCard";

const StyledCard = styled(HorizontalCard)`
  padding: 1rem;
  align-items: center;

  cursor: pointer;

  .image {
    flex: 0 0 6.5rem;
    margin-right: 2rem;
  }
  .content {
    align-self: stretch;
    display: flex;
    flex-direction: column;

    h3 {
      flex: 0 0 20%;
    }
  }
`

const CategoryCard = ({ title, image, preview, url }) => {
  return (
    <StyledCard onClick={(event) => {
      event.preventDefault();
      navigate(url);
    }}>
      <Img className="image" fixed={ image.fixed } />
      <div className="content">
        <h3>{title}</h3>
        <div>{preview}</div>
      </div>
    </StyledCard>
  )
}

export default CategoryCard