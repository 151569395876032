import CategoryCard from "./CategoryCardDTO";

export default class CategoryCardFactory {
  static createCategoryCard(categoryCardApiObject) {
    const categoryCardDTO = new CategoryCard(categoryCardApiObject);
    return categoryCardDTO;
  }

  static fromArray(categoryCardApiArray) {
    const categoryCards = [];
    categoryCardApiArray.forEach(categoryCardApiObject => {
      const categoryCardDTO = CategoryCardFactory.createCategoryCard(categoryCardApiObject);
      categoryCards.push(categoryCardDTO);
    })
    return categoryCards;
  }
}